import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quotation-with-image" }
const _hoisted_2 = {
  key: 1,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PicfittedImage = _resolveComponent("PicfittedImage")!

  return (_openBlock(), _createElementBlock("blockquote", _hoisted_1, [
    ($props.quotationWithImage.image)
      ? (_openBlock(), _createBlock(_component_PicfittedImage, {
          key: 0,
          src: $props.quotationWithImage.image?.url,
          width: "800"
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    ($props.quotationWithImage?.quote)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.quotationWithImage?.quote), 1))
      : _createCommentVNode("", true)
  ]))
}