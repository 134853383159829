
import {ContentBlockBackground, QuotationWithImage} from '@/types/graphql.sdk'
import PicfittedImage from "@/components/PicfittedImage.vue";

export default {
    components: {PicfittedImage},
    props: {
        bgColor: ContentBlockBackground,
        quotationWithImage: Object as () => QuotationWithImage
    }
}
